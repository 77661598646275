<template lang="pug">
  v-container
    v-row
      v-col.pb-5(cols="12")
        v-toolbar(
          color="primary"
          dark
          elevation="0"
          dense
        )
          span New Stock
          v-spacer
          v-btn(
            text
            class="mr-2"
            :loading="stockPosting"
            @click="storeStock"
          )
            span ADD
          v-icon mdi-clipboard
        v-alert(
          class="mt-2"
          type="success"
          dismissible
          v-if="stockPostSuccess"
        )
          span.text-uppercase {{ stockPostSuccess }}
        v-alert(
          class="mt-2"
          type="error"
          dismissible
          v-if="stockPostErrors.errors"
        )
          span {{ stockPostErrors.errors.first() }}
      v-col(
        class="py-0"
        lg="2"
        cols="12"
      )
        v-text-field(
          label="Stock Code"
          :value="stockId"
          readonly
          dense
          outlined
          :error-messages="stockPostErrors.id || []"
        )
      v-col(
        class="py-0"
        lg="2"
        cols="12"
      )
        v-autocomplete(
          :items="stockCatList"
          :error-messages="stockPostErrors.stock_category_id || []"
          :loading="stockCatGetting"
          label="Stock Category"
          v-model="stockCategory"
          :filter="stockCatFilter"
          outlined
          dense
          :allow-overflow="false"
          item-value="id"
          return-object
        )
          template(v-slot:selection="{ item }")
            span.primary--text {{ item.id }}
          template(v-slot:item="{ item }")
            span.primary--text {{ item.id }}
            span.blue-grey--text.mx-1.text-caption {{ item.name }}
      v-col(
        class="py-0"
        lg="2"
        cols="12"
      )
        v-text-field(
          label="Product Name"
          v-model="productName"
          :error-messages="stockPostErrors.product_name || []"
          outlined
          dense
        )
      v-col(
        class="py-0"
        lg="2"
        cols="12"
      )
        v-text-field(
          label="Brand Name"
          v-model="brandName"
          :error-messages="stockPostErrors.brand_name || []"
          outlined
          dense
        )
      v-col(
        class="py-0"
        lg="2"
        cols="12"
      )
        v-text-field(
          label="Product Type"
          v-model="productType"
          :error-messages="stockPostErrors.product_type || []"
          outlined
          dense
        )

      v-col(
        class="py-0"
        lg="2"
        cols="12"
      )
        v-text-field(
          label="Description"
          v-model="description"
          :error-messages="stockPostErrors.description || []"
          outlined
          dense
        )
</template>
<script>
import stockRepository from '@/repositories/stock.repository'
import { mapState } from 'vuex'
import { postVars, getVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import stockCatRepository from '@/repositories/stock-category.repository'

const stockPostDelay = searchDelay()
const stockCatGetDelay = searchDelay()

const inputVars = () => ({
  stockId: null,
  stockCategory: {},
  productName: null,
  productType: null,
  brandName: null,
  description: null,
})

const removeCategory = (stock = '0', category) => stock.replace(category, '')

const genStockId = ({ stockId = '0', stockCategoryId = null }) => {
  const TOTAL_STRING_LENGTH = 5
  if (!stockCategoryId) return
  const CURRENT_ITERATION = (removeCategory(stockId, stockCategoryId).extractFirstInt() + 1)
    .toString()
    .padStart(TOTAL_STRING_LENGTH, '0')
  return `${stockCategoryId}${CURRENT_ITERATION}`
}

const incrementStockId = ({ prefix = null, count = 0, latest = null }) => {
  let latestNumber = 0
  if (!prefix || !latest || latest.extractFirstString() !== prefix) return
  if (count > (latestNumber = latest.extractFirstInt())) return
  return latestNumber + 1
}

export default {
  name: 'CreateStock',
  created () {
    this.getStockCat()
    this.websocketEvents()
  },
  data () {
    return {
      ...inputVars(),
      ...postVars('stock'),
      ...getVars('stock-cat'),
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
    }),
  },
  watch: {
    stockCategory (item) {
      if (!item.id) {
        this.stockId = null
        return
      }
      const latestStockId = item.latestStock.id
      this.updateStockId(latestStockId)
    },
  },
  methods: {
    storeStock () {
      if (this.stockPosting) return
      this.stockPosting = true
      stockPostDelay(() => {
        const data = this.getInputData()
        stockRepository.store(data)
          .then(this.storeSuccess)
          .catch(e => { this.stockPostErrors = errorHandler(e) })
          .then(() => { this.stockPosting = false })
      })
    },
    storeSuccess ({ data }) {
      this.stockPostSuccess = `New Stock ${data.id} Successfully Stored`
      this.reset(this.$objectFilterKeys(inputVars(), ['stockId', 'stockCategory']))
    },
    getStockCat () {
      if (this.stockCatGetting) return
      this.stockCatGetting = true
      this.stockCatGetErrors = []
      stockCatGetDelay(() => {
        stockCatRepository.index()
          .then(({ data }) => { this.stockCatList = this.mapStockCategories(data) })
          .catch(e => { this.stockCatGetErrors = errorHandler(e) })
          .then(() => { this.stockCatGetting = false })
      })
    },
    reset (defaults) {
      Object.keys(defaults).forEach(e => {
        this[e] = defaults[e]
      })
    },
    getInputData () {
      return {
        id: this.stockId,
        stock_category_id: (this.stockCategory.id || null),
        product_name: this.productName,
        product_type: this.productType,
        brand_name: this.brandName,
        description: this.description,
      }
    },
    websocketEvents () {
      this.echo.private('database.event')
        .listen('DBStoreEvent', this.DBStoreEvent)
    },
    DBStoreEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'Stock') {
        this.stockDBStoreEvent(data)
      }
    },
    stockDBStoreEvent (newStock) {
      this.updateStockCategoryList(newStock)
      this.updateStockId(newStock.id)
    },
    updateStockCategoryList (newStock) {
      this.stockCatList = this.stockCatList.map(item => {
        if (item.id === newStock.stock_category_id) item.latestStock = newStock
        return Object.assign({ ...item })
      })
    },
    mapStockCategories (data) {
      return data.map(item => {
        const result = this.$objectChangeKeyCase(item)
        result.latestStock = this.$objectChangeKeyCase(result.latestStock || undefined)
        return result
      })
    },
    stockCatUpdate ({ updated }) {
      const { original } = updated
      this.getStockCat()
      if (!this.stockCategory.id || !this.stockCategory.latestStock.id || !original.id) return

      this.stockCategory.latestStock = original
      this.stockId = genStockId(this.stockCategory)
    },
    stockCatFilter (item, queryText, itemText) {
      const rxItem = new RegExp(`${item.id}|${item.name}`, 'i')
      const rxQueryText = new RegExp(queryText, 'i')
      return rxQueryText.test(rxItem)
    },
    updateStockId (stockId = '0') {
      const stockCategoryId = this.stockCategory.id
      this.stockId = genStockId({ stockCategoryId, stockId })
    },
  },
}
</script>
